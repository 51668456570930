
@import url("https://use.typekit.net/uqm2soi.css");



body {
  font-family: sofia-pro, sans-serif;
  font-size: 23px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;


  background-color: var(--mantine-color-body);
  color: var(--mantine-color-text);

  -webkit-font-smoothing: var(--mantine-webkit-font-smoothing);
  -moz-osx-font-smoothing: var(--mantine-moz-font-smoothing);
}

.RobCon h1 h2 h3 h4 h5 h6 {
  font-family: greycliff-cf, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

p {
  font-size: 1.3rem;
  font-optical-sizing: auto;
  word-wrap: break-word;
  font-style: normal;
  font-weight: 200;
  line-height: 2.2rem;
  margin-bottom: 2.2rem;
}


@media screen and (max-width: 74em) {

  p {
    font-size: 1.2rem;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 300;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
  }

  .imageAbout {
    width: 80% !important;
  }

}




@media screen and (max-width: 64em) {

  p {
    font-size: 1.1rem;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 300;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
  }

  .imageAbout {
    width: 60% !important;
    margin-bottom: 20px !important;
  }

}

.WrittenFont {
  font-family: "Edu VIC WA NT Beginner", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}


.backArmy {
  background-color: #0c8599
}



/* h1.mantine-Title-root {
  font-size: 5rem;

  @media (max-width: --mantine-breakpoint-xl) {
      font-size: 1.5rem;
  }
} */

.LogoMain {
  height: auto;
  width: 180px;
}


.HeroHeaderSVG {
  position: relative;
  top: 62px;
  z-index: 1;
}

.HeroHeaderSVGVM {
  position: relative;
  top: 20px;
  z-index: 1;
}

.HeroHeaderSVGCS {
  position: relative;
  top: 92px;
  z-index: 1;
}


.HeroHeaderSVGM {
  position: relative;
  top: 130px;
  z-index: 1;
}

[data-mantine-color-scheme='light']
.BackHero {
  opacity: 0.4;
}

.next {
  background-color: #0c8599 !important;
}

.cardNext {
  &:hover {
    background-color: #0b7285 !important;
  }
}


.acsback {
  background-color: #5B8A35 !important;
}



 /* App Shell */



.theme80 {
    background-color: var(--mantine-color-80)!important;
  }

.theme90 {
    background-color: var(--mantine-color-90)!important;
  }

.theme100 {
    background-color: var(--mantine-color-100)!important;
  }



 /* Text Colour */

 .theme0 {
  color: var(--mantine-fontcolor-0)!important;
}

.themePeople{
stroke: var(--mantine-fontcolor-0)!important;

}
  
 /* Carousel-indicator */
  
.mantine-Carousel-indicator {
  background-color: var(--mantine-color-Indicator)!important;
}

.mantine-Carousel-viewport { 
  flex: 0 0 auto; /* Adapt slide size to its content */
  min-width: 0;
  max-width: 100%; /* Prevent from growing larger than viewport */
}


 /* Contact Form Modal */

.mantine-Modal-content {
 background-color: var(--mantine-color-90)!important;
 border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
}

.mantine-Modal-header {
  background-color: var(--mantine-color-90)!important;
 }

 .mantine-Modal-header {
  padding-bottom: 0px !important;
 }

 .mantine-Modal-close {
outline: none !important; 
 }

 


.vjs-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* Change the border of the big play button. */
.video-js .vjs-big-play-button {
  background-color: #0c8599;
  border: none;
}


.video-js .vjs-big-play-button {
  &:hover {
    background-color: #0b7285 !important;
  }
}

/* Change the color of various "bars". */
.video-js  .vjs-volume-level,
.video-js  .vjs-play-progress,
.video-js  .vjs-slider-bar {
  background-color: #0b7285 !important;
}