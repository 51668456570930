.controlHome {
    width: 40px;
    height: 40px;
    transition: width 250ms ease;
    color: white !important;
    background-color: rgb(12, 133, 153) !important;
    opacity: 1;
    position: relative;
    bottom: 150px;



    &:hover {opacity: 0.8;}
  
    &[data-active] {
      width: 100px;
    }
  }



  .controlArmy {
    width: 40px;
    height: 40px;
    transition: width 250ms ease;
    color: white !important;
    background-color: rgb(0, 150, 55) !important;
    opacity: 1;
    position: relative;
    bottom: 70px;



    &:hover {opacity: 0.8;}
  
    &[data-active] {
      width: 40px;
    }
  }


